@tailwind base;
@tailwind components;
@tailwind utilities;

.lds-default {
    display: inline-block;
    position: absolute;
    width: 90px;
    height: 90px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.lds-default div {
    position: absolute;
    width: 9px;
    height: 9px;
    /* background: #000; */
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
}

.lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
}

.lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
}

.lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
}

.lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
}

.lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
}

.lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
}

.lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
}

.lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
}

.lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
}

.lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
}

.lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
}

@keyframes lds-default {

    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
        background-color: #20696b;
    }
}

body {
    direction: rtl;
}

.rtl {
    direction: rtl !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    background: rgb(243 253 255);
    padding: 20px;
    border-radius: 8px;
    position: relative;
    min-width: 300px;
    max-width: 95%;
    max-height: 80%;
    overflow-y: auto;
}

.popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.report-dev {
    margin: auto;
    width: 60%;
    border: 5px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to right, #50a394, #7fe9da, #9ef7e7);
    border-radius: 15px;
    /* Adjust this value to change the radius */
    padding: 20px;
    text-align: center;
}

.date-range {
    width: 60%;
    border: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #ff0000, #ffff00, #008000);
    border-radius: 15px;
    /* Adjust this value to change the radius */
    padding: 5px;
    text-align: center;
    margin: auto auto 15px;
}

.span-txt {
    color: #004b97;
    text-shadow: 2px 2px #c5fbff;
}