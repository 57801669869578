.cland {
    text-align: center;
    margin-top: 5px;
  }
  
  .calendar-container {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .day {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 5px;
  }
  
  .red {
    background-color: red;
    color: white;
    border-radius: 15px;
  }
  .green {
    background-color: green;
    color: white;
    border-radius: 15px;
  }
  abbr{
    color: black;
  }
  
  .bold {
    font-weight: bold;
  }
  